import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const InlineNotification = makeShortcode("InlineNotification");
const Title = makeShortcode("Title");
const CardGroup = makeShortcode("CardGroup");
const MiniCard = makeShortcode("MiniCard");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<MiniCard>`}</inlineCode>{` component can be used in place of a `}<inlineCode parentName="p">{`<ResourceCard>`}</inlineCode>{` if your
content allows it. Unless it is sitting beside your main content, it should
always be wrapped inside of a `}<inlineCode parentName="p">{`<CardGroup>`}</inlineCode>{`. This allows the correct gutter and
border placement between a group of cards.`}</p>
    </PageDescription>
    <InlineNotification mdxType="InlineNotification">
  Although the mini-resource card has a similar geometry to the button
  component, they should not be used in place of a button. Buttons encourage
  action from the user and affect the website's front-end or back-end. The
  resource cards, both large and mini are essentially links. They are used for
  navigation and actions that do not affect the website.
    </InlineNotification>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">Group</Title>
    <CardGroup mdxType="CardGroup">
      <MiniCard title="26 characters per MiniCard" href="/demo" mdxType="MiniCard">
        <img {...{
          "src": "/18f3a3dc6c5ae9221446f35a437b2780/adobe-icon.svg",
          "alt": "Adobe Acrobat icon"
        }}></img>
      </MiniCard>
      <MiniCard title="Use the default icon" href="/demo" mdxType="MiniCard" />
      <MiniCard title="Choose from other icons" href="/demo" actionIcon="arrowRight" mdxType="MiniCard" />
      <MiniCard title="Or bring your own" href="/demo" mdxType="MiniCard">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVQ4y4WTT2hcdRDHv2/f1rpKFFsseO1JUFFviggVPBT/nISCeNAc2ibZl7XENGmOVvRQRWqoVYMigkYS9+1mN4sHDy0oNHYtBHe3hwaUmjZ2897L262m63Z3fzMy8162aQ/6g+E3zMz38+Y3wwPnABIrwqJFoMt7QcvJXfSr/TBVB/aZZbxKK0+N0cozb6lfG3hOc8vJ3R3eoxoqwGIXIBcxbBEJ/hrgL7GLzts/m1pyk6o726YCptp9TOvTTOunmGr3s8YkJzXn7TJ/hQf5m5ghQO3wB8viOYCzcOmczebiTjYVi6l6N1P9Q8PNs11unulS/aSh6j0kOamhJZs5iwJnAVpKYAtoS4BdjLMLpvKOjqnZhio20Z/HiZtnicICUbgQ+dfeJaokSWvKOzrkgtnFlMCEBS7o25+WBOUEaBtTBdPVCQEwhQvM4aKa+s0zTFenSGt+SRDlLFati2eFBa4gxXmscA7MefToApjWRomv/8jUKDE1v2eOTXyJaW7tCEmtaGLtb3wB96I3i6yZB5t5qyM3XXqFeKNE7H3L5M0x+3N6b/clxxslppUDFGu7cvdmUUA4jtNBBrzxJrqBA2p/PkBcShHlU8wLKaaF6N7ua66UoptfDFAwClKtMMYxgxsfAP4IvvPTYG8IncakzoPYBVFWfRm6Wjwr8TXfOAb2DqMjWn8E+dY0gGuvA423kfQdLAVOBG1/AuYiSMG5eFkyp1z8sSKo/SnYH0JHNL6DcjiFu+pvAPAd2L4D+KPYEzi47A+DG5Poaie5CEq3uiON5cCNY+hKbeBg1XfwkDIc2PDT26AOHgscbHpD4H8+Ro+Lfcj27rh9Gj2pCRy0fAdPKCONpNwRcORWIMhgv3w5nFCA2QL1n+/CyJy1uwxe6sOGI448Fd5hwB+8DXpIuzwFo7PcWk4RJJ1rdxmMbDXivQb4h1QXHXGCo0BwRL+SqA8C3hDeC8cV1JW5xfPshkd1syfWB6NaX7RjUWO3HQloMgOrfgC48jKwfhCzrZPaWUes9RHYO4j5Ky8A5lHVWDL74E5YH+r0zfp9H5AHEuEEfjJz8jeAw0mcKwPJy8/rUxPx/P77CNAbjgR/7Acu7sUD7RmstmewdukR7F59MYZFNbj52f8Aw7F482ng+nHYf58Abkzj8dY0ntx8H/jrHdi6uHQ0uzvPv7ZAHJs9yKMlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Github icon",
            "title": "Github icon",
            "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
            "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </MiniCard>
    </CardGroup>
    <Title mdxType="Title">Aside</Title>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <p>{`When you have the mini resource card sitting aside the main content, be sure to
add the `}<inlineCode parentName="p">{`gutterLg`}</inlineCode>{`, properties to the `}<inlineCode parentName="p">{`<MiniCard>`}</inlineCode>{`. This will ensure the
MiniCard has the appropriate gutters at the approriate breakpoints.`}</p>
      </Column>
      <MiniCard gutterLg title="By itself, as an Aside" href="https://gatsby-theme-carbon.now.sh" mdxType="MiniCard">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "200px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "95%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAADe0lEQVQ4y4WTT2hcdRDHv2/f1rpKFFsseO1JUFFviggVPBT/nISCeNAc2ibZl7XENGmOVvRQRWqoVYMigkYS9+1mN4sHDy0oNHYtBHe3hwaUmjZ2897L262m63Z3fzMy8162aQ/6g+E3zMz38+Y3wwPnABIrwqJFoMt7QcvJXfSr/TBVB/aZZbxKK0+N0cozb6lfG3hOc8vJ3R3eoxoqwGIXIBcxbBEJ/hrgL7GLzts/m1pyk6o726YCptp9TOvTTOunmGr3s8YkJzXn7TJ/hQf5m5ghQO3wB8viOYCzcOmczebiTjYVi6l6N1P9Q8PNs11unulS/aSh6j0kOamhJZs5iwJnAVpKYAtoS4BdjLMLpvKOjqnZhio20Z/HiZtnicICUbgQ+dfeJaokSWvKOzrkgtnFlMCEBS7o25+WBOUEaBtTBdPVCQEwhQvM4aKa+s0zTFenSGt+SRDlLFati2eFBa4gxXmscA7MefToApjWRomv/8jUKDE1v2eOTXyJaW7tCEmtaGLtb3wB96I3i6yZB5t5qyM3XXqFeKNE7H3L5M0x+3N6b/clxxslppUDFGu7cvdmUUA4jtNBBrzxJrqBA2p/PkBcShHlU8wLKaaF6N7ua66UoptfDFAwClKtMMYxgxsfAP4IvvPTYG8IncakzoPYBVFWfRm6Wjwr8TXfOAb2DqMjWn8E+dY0gGuvA423kfQdLAVOBG1/AuYiSMG5eFkyp1z8sSKo/SnYH0JHNL6DcjiFu+pvAPAd2L4D+KPYEzi47A+DG5Poaie5CEq3uiON5cCNY+hKbeBg1XfwkDIc2PDT26AOHgscbHpD4H8+Ro+Lfcj27rh9Gj2pCRy0fAdPKCONpNwRcORWIMhgv3w5nFCA2QL1n+/CyJy1uwxe6sOGI448Fd5hwB+8DXpIuzwFo7PcWk4RJJ1rdxmMbDXivQb4h1QXHXGCo0BwRL+SqA8C3hDeC8cV1JW5xfPshkd1syfWB6NaX7RjUWO3HQloMgOrfgC48jKwfhCzrZPaWUes9RHYO4j5Ky8A5lHVWDL74E5YH+r0zfp9H5AHEuEEfjJz8jeAw0mcKwPJy8/rUxPx/P77CNAbjgR/7Acu7sUD7RmstmewdukR7F59MYZFNbj52f8Aw7F482ng+nHYf58Abkzj8dY0ntx8H/jrHdi6uHQ0uzvPv7ZAHJs9yKMlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Sketch icon",
            "title": "Sketch icon",
            "src": "/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png",
            "srcSet": ["/static/28b17f4b368ca58d4d47e5136b85f0d3/59afc/sketch-icon.png 200w"],
            "sizes": "(max-width: 200px) 100vw, 200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </MiniCard>
    </Row>
    <h2>{`Code`}</h2>
    <Title mdxType="Title">Group</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/MiniCard/MiniCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard",
        "path": "components/MiniCard/MiniCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard"
      }}>{`<CardGroup>
  <MiniCard title="26 characters per MiniCard" href="/demo">
    ![Adobe Acrobat icon](/images/adobe-icon.svg)
  </MiniCard>
  <MiniCard title="Use the default icon" href="/demo" />
  <MiniCard
    title="Choose from other icons"
    href="/demo"
    actionIcon="arrowRight"
  />
  <MiniCard title="Or bring your own" href="/demo">
    ![Github icon](/images/sketch-icon.png)
  </MiniCard>
</CardGroup>
`}</code></pre>
    <Title mdxType="Title">Aside</Title>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/MiniCard/MiniCard.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard",
        "path": "components/MiniCard/MiniCard.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/MiniCard"
      }}>{`<Row>
<Column colLg={8}>

When you have the mini resource card sitting aside the main content, be sure to
add the \`gutterLg\`, properties to the \`<MiniCard>\`. This will ensure the
MiniCard has the appropriate gutters at the approriate breakpoints.

</Column>
<MiniCard
  gutterLg
  title="By itself, as an Aside"
  href="https://gatsby-theme-carbon.now.sh"
  >

![Sketch icon](/images/sketch-icon.png)

</MiniCard>
</Row>
`}</code></pre>
    <h3>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`property`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`propType`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`required`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`node`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use 32x32 image as child, will display in right-hand corner of the card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`href`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Set url for card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Title for the card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`launch`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Action icon, default is launch, options are `}<inlineCode parentName="td">{`launch`}</inlineCode>{`, `}<inlineCode parentName="td">{`arrowRight`}</inlineCode>{`, `}<inlineCode parentName="td">{`download`}</inlineCode>{`, `}<inlineCode parentName="td">{`disabled`}</inlineCode>{`, `}<inlineCode parentName="td">{`email`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Add custom class name`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      